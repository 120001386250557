/********** Custom *************/
body {
  padding-bottom: 40px;
  color: #5a5a5a;
  background-color: #ECEFF1;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  padding-top: 60px;
}

.navbar-inverse {
  background-color: #546E7A;
  border-color: #455A64;
}

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
  background-color: #455A64;
}

.navbar-inverse .navbar-nav>li>a, .navbar-inverse .navbar-text {
  color: #FFF;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #455A64;
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
  background-color: #455A64;
}
.navbar-inverse .navbar-toggle {
  border-color: #455A64;
}
.navbar-brand img{margin-top: -10px;}

* {
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
}

footer{
  margin-top: 20px;
}

.table-borderless > thead > tr > th,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > tbody > tr > td,
.table-borderless > tfoot > tr > td {
  border: none;
}

img.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alert-float {
  position: fixed;
  left: 50%;
  margin-left: -250px;
  width: 500px;
  z-index: 9999;
}
.alert-float .alert{
  margin-bottom: 5px;
}

.login-holder {
  width: 430px;
  padding: 15px;
  margin: 0 auto;
}

.login-box{
  padding:50px 50px 60px;
  background: #FFF;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  background: white;
  -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 10px;
}

.login-box img, .login-box h2{
  padding-bottom: 10px;
}


.form-login{
  margin-bottom: 20px;
}

@media only screen and (max-width: 480px) {
  .container{
    padding:0px;
  }

  .login-holder {
    width: auto;
    margin-top: -60px;
    padding: 0px;
  }
  .login-box{
    padding:30px;
  }
}

.payments div{
  cursor: pointer;
}

.payments .bar.bar-energized {
  background-color: #f0b840;
  border-color: #d39511;
  background-image: linear-gradient(0deg, #d39511, #d39511 50%, transparent 50%);
}

.form.row .col-sm-4 {padding:0px 5px 0 5px;}

.table > thead > tr > td.energized,
.table > tbody > tr > td.energized,
.table > tfoot > tr > td.energized,
.table > thead > tr > th.energized,
.table > tbody > tr > th.energized,
.table > tfoot > tr > th.energized,
.table > thead > tr.energized > td,
.table > tbody > tr.energized > td,
.table > tfoot > tr.energized > td,
.table > thead > tr.energized > th,
.table > tbody > tr.energized > th,
.table > tfoot > tr.energized > th {
  background-color: #f0b840;
  color: white;
}
.table-hover > tbody > tr > td.energized:hover,
.table-hover > tbody > tr > th.energized:hover,
.table-hover > tbody > tr.energized:hover > td,
.table-hover > tbody > tr.energized:hover > th {
  background-color: #d0e9c6;
}

.payments .well.selected{
  background-color: #F22A0A;
  color: #fff;
}

/* Common styles for all types */
.bs-callout {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #eee;
}
.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-callout p:last-child {
  margin-bottom: 0;
}

/* Variations */
.bs-callout .danger {
  background-color: #fdf7f7;
  border-color: #eed3d7;
}
.bs-callout .danger h4 {
  color: #b94a48;
}
.bs-callout .warning {
  background-color: #faf8f0;
  border-color: #faebcc;
}
.bs-callout .warning h4 {
  color: #8a6d3b;
}
.bs-callout .info {
  background-color: #f4f8fa;
  border-color: #bce8f1;
}
.bs-callout .info h4 {
  color: #34789a;
}



.btn{
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.btn-green {
  background: #65c31f;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
  border-color: #57af17;
  font-weight: bold;
  background-color: #65c31f;
 	/*background-image: -moz-linear-gradient(#94d52d, #65c31f);
 	background-image: -o-linear-gradient(#94d52d, #65c31f);
 	background-image: -ms-linear-gradient(#94d52d, #65c31f);
 	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #94d52d), color-stop(1, #65c31f));
 	background-image: -webkit-linear-gradient(#94d52d, #65c31f);*/
 }

 .btn-green:hover {
 	color: #fff;
 	background: #57af17;
 	background-position: 0 0;
 }
 .btn-green.disabled, .btn-green[disabled] {
 	color: #fff;
 	background: #50a300;
 }

 .btn-green:active {
 	background: #65c31f;
 }


 /********** Custom *************/
 /* Common styles for all types */

 .static.popover {
  /*position: relative;*/
  display: block;
  float: left;
  width: 260px;
  margin: 20px;
}
.show-payment{
  top: 25px;
  left: -75px;
}

footer .form-signup{
 margin: 10px auto 50px auto;
}

footer .copyright{
 padding: 10px;
}
.form-signup{
  display: inline-block;
  width: 450px;
  padding-top: 10px;
}

a.nav-signup{
  color:#FFF !important;
  background: #65c31f;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

a.nav-signup:hover{
  color:#FFF !important;
  background: #57af17 !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.carousel-caption .form-signup p{
  color: #EEE;
  text-shadow:none;
  font-size: 14px;

  margin-bottom: 0px;
}

.carousel-caption{
  left: 0;
  right: 0;
  width: auto;
  position: relative;
  margin-top: 80px;
  padding-bottom: 0px;
  bottom: 15px;
}

.carousel .video-wrapper{
  background: rgba(0,0,0,0.2);
  border-radius: 3px;
  padding: 10px 10px;
}

.carousel-caption .video-play {
  margin: 20px 0;

  width: 78px;
  height: 78px;
}
.carousel-elements:hover .video-play {
  opacity: .7;

}

.carousel-caption .btn{
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.carousel-elements{
  cursor: pointer;
  padding: 25px 0;
}

.carousel-caption h1, .carousel-caption h2, .carousel-caption h3{
  margin-top: 10px;
}

.form-signup .form-group, .form-signup button{
  margin-bottom: 5px;
}

.carousel-caption .modal-body{
  padding: 10px;
}

/* Popover */
.carousel-caption .popover-content{
  color:#000;
  text-shadow:none;
}
.carousel-caption .sub-
.white-panel {
  background-color: #fff;
  border: 1px solid #dadada;
  padding: 0px;
  margin-bottom: 40px;
  color: #333;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}

.navbar .brand small{
  font-size:10px;
}

#progress-tracker.pagination ul > li.complete > a{
  background: #65c31f;
  color:#fff;
  font-weight: bold;
  background-image: -moz-linear-gradient(#94d52d, #65c31f);
  background-image: -o-linear-gradient(#94d52d, #65c31f);
  background-image: -ms-linear-gradient(#94d52d, #65c31f);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #94d52d), color-stop(1, #65c31f));
  background-image: -webkit-linear-gradient(#94d52d, #65c31f);
}

#progress-tracker.pagination ul > li.complete > a:hover, #progress-tracker.pagination ul > li.active > a{
  background: #57af17;
  color:#fff;
}

.prg-cont{
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 600;
  pointer-events: none;
  -webkit-transition: top 0.218s ease-in-out;
  -moz-transition: top 0.218s ease-in-out;
  -o-transition: top 0.218s ease-in-out;
  -ms-transition: top 0.218s ease-in-out;
  transition: top 0.218s ease-in-out;
}

.prg-status{
  display: inline-block;
  pointer-events: auto;
  padding: 6px 25px 4px 10px;
  line-height: 30px;
  background-color: #fff;
  color: #444;
  font-weight: bold;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Figure Section*/
#home-management .figure-img{
  float: left;
  width: 46%;
}
#home-management figcaption{
  float: right;
  margin-top: 6%;
  width: 48%;
}

#home-reporting .figure-img{
  float: right;
  width: 46%;
}
#home-reporting figcaption{
  float: left;
  margin-top: 6%;
  width: 48%;
}

#home-green .figure-img{
  float: left;
  width: 46%;
}
#home-green figcaption{
  float: right;
  margin-top: 6%;
  width: 48%;
}

.inner:after{
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.intro-p{
  font-size: 16px;
  line-height: 26px;
}
/* Auto Save */
.icon-auto{
  background-image:none;
  height: 18px;
  width: 19px;
}
.icon-auto.changed{
  background: url("/img/changed.gif") -0px -0px no-repeat;
}
.icon-auto.saving{
  background: url("/img/saving.gif") -0px -0px no-repeat;
}
.icon-auto.saved{
  background: url("/img/saved.gif") -0px -0px no-repeat;
}

.flex-video {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
  margin-bottom: 16px;
  overflow: hidden;
}

.flex-video.widescreen { padding-bottom: 57.25%; }
.flex-video.vimeo { padding-top: 0; }

.flex-video iframe,
.flex-video object,
.flex-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-device-width: 800px), only screen and (device-width: 1024px) and (device-height: 600px), only screen and (width: 1280px) and (orientation: landscape), only screen and (device-width: 800px), only screen and (max-width: 767px) {
  .flex-video { padding-top: 0; }
}

.vertically-center{
  float:none;
  display:inline-block;
  vertical-align:middle;
  margin-right:-4px;
}

/* Signup Div*/
.signup{
	margin: 30px 0;
}

/* Special class on .container surrounding .navbar, used for positioning it into place. */
.navbar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

/* Flip around the padding for proper display in narrow viewports */
.navbar-wrapper .container {
  padding-left: 0;
  padding-right: 0;
}
.navbar-wrapper .navbar {
  padding-left: 15px;
  padding-right: 15px;
}

/* Fuel Edit Trip */
.fuel-block .form-group, .row-form .form-group {
  margin-bottom: 0px;
}
.row-form.table > tbody > tr > td{
  border-top:none;
  padding:2px;
}

tr.clickable-row{
  cursor: pointer;
}
.table.features{
  margin: 0;
}
.table.features > tbody > tr > td{
  vertical-align:middle;
  border: 0;
  padding: 5px 0;
}
.table.features p{
  margin:0;
  padding: 0 5px;
}


.table.features tr {
   border-bottom: 1px solid #CCC;
}
.table.features tr:last-child {
   border-bottom: none;
   margin: 0;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  height: 500px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel .item {
  height: 500px;
  background-color: #777;
}
.carousel-inner > .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 500px;
}



/* MARKETING CONTENT
-------------------------------------------------- */

/* Pad the edges of the mobile views a bit */
.main {
/*  background-color: #fff;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 2px 5px rgba(0,0,0,0.14844);
  -webkit-box-shadow: 0 2px 5px rgba(0,0,0,0.14844);
  box-shadow: 0 2px 5px rgba(0,0,0,0.14844);
  padding: 20px;*/
}

/* Center align the text within the three columns below the carousel */
.main .col-lg-4 {
  text-align: center;
  margin-bottom: 20px;
}
.main h2 {
  font-weight: normal;
  margin-bottom: 20px;
}
.main .col-lg-4 p {
  margin-left: 10px;
  margin-right: 10px;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 80px 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -1px;
}



/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {

  /* Remove the edge padding needed for mobile */
  .main {
    padding-left: 20px;
    padding-right: 20px;

  }

  /* Navbar positioning foo */
  .navbar-wrapper {
    margin-top: 15px;
  }
  .navbar-wrapper .container {
    padding-left:  15px;
    padding-right: 15px;
  }
  .navbar-wrapper .navbar {
    padding-left:  0;
    padding-right: 0;
  }

  /* The navbar becomes detached from the top, so we round the corners */
  .navbar-wrapper .navbar {
    border-radius: 4px;
  }

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }

}

@media (min-width: 992px) {
  .featurette-heading {
    margin-top: 120px;
  }
}

/* Context Map Menu */


.contextMenu {
  position: absolute;
  z-index: 1000;

  display:none;

  min-width: 120px;

  background: #eee;
  border:1px solid #777;

}

.contextMenu ul {

  padding: 3px 0px;
  margin: 0px;

  border: solid 1px #fff;
}

.contextMenu li {
  list-style: none;
  padding: 0px 1px;
  margin: 0px;
}
.contextMenu a {
  display: block;
  color: #000;
  text-decoration: none;
  line-height: 22px;
  height: 22px;
  padding: 1px 8px;
}

.contextMenu li.hover a {
  padding: 0px 7px;
  background-color: #DFE6F5;
  border: 1px solid #A2C1FA;
  border-radius:2px;
}

.contextMenu li.separator div {
  margin: 3px 0px;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #fff;
}

/* Override .has-feedback */
.has-feedback .form-control-feedback {
  top: 0;
}
.has-error.has-feedback select{width: 80%;}
.has-feedback select.form-control {padding-right: inherit;}

select.form-control{
  padding: 6px;
}

/* Make for smaller in Modal */
.modal-dialog .form-group {margin-bottom: 5px;}


/* Cards from Bootstrap 4
-------------------------------------------------- */

.card {
  position: relative;
  margin-bottom: 20px;
  border: .0625rem solid #e5e5e5;
  background-color: #FFF;
  box-shadow: 0 -1px 0 #e5e5e5,0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
}

.card-block {
  padding: 1.25rem;
}

.card-title {
  margin-top: 0;
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-radius: .25rem .25rem 0 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-radius: 0 0 .25rem .25rem;
}

.card-header {
  padding: .75rem 1.25rem;
  background-color: initial;
  border-bottom: .0625rem solid #e5e5e5;
}
.card-header h4{
  margin:0;
}
.card-header .pagination{
  margin: 0;
}

.card-header:first-child {
  border-radius: .1875rem .1875rem 0 0;
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: #f5f5f5;
  border-top: .0625rem solid #e5e5e5;
}

.card-footer:last-child {
  border-radius: 0 0 .1875rem .1875rem;
}

.card-primary {
  background-color: #0275d8;
  border-color: #0275d8;
}

.card-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.card-info {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.card-warning {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.card-danger {
  background-color: #d9534f;
  border-color: #d9534f;
}

.card-inverse .card-header,
.card-inverse .card-footer {
  border-bottom: .075rem solid rgba(255, 255, 255, .2);
}

.card-inverse .card-header,
.card-inverse .card-footer,
.card-inverse .card-title,
.card-inverse .card-blockquote {
  color: #fff;
}

.card-inverse .card-link,
.card-inverse .card-text,
.card-inverse .card-blockquote > footer {
  color: rgba(255, 255, 255, .65);
}

.card-inverse .card-link:focus,
.card-inverse .card-link:hover {
  color: #fff;
}

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0;
}

.card-img {
  border-radius: .25rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img-top {
  border-radius: .25rem .25rem 0 0;
}

.card-img-bottom {
  border-radius: 0 0 .25rem .25rem;
}

.card-deck {
  display: table;
  table-layout: fixed;
  border-spacing: 1.25rem 0;
}

.card-deck .card {
  display: table-cell;
  width: 1%;
  vertical-align: top;
}

.card-deck-wrapper {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

.card-group {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.card-group .card {
  display: table-cell;
  vertical-align: top;
}

.card-group .card + .card {
  margin-left: 0;
  border-left: 0;
}

.card-group .card:first-child .card-img-top {
  border-top-right-radius: 0;
}

.card-group .card:first-child .card-img-bottom {
  border-bottom-right-radius: 0;
}

.card-group .card:last-child .card-img-top {
  border-top-left-radius: 0;
}

.card-group .card:last-child .card-img-bottom {
  border-bottom-left-radius: 0;
}

.card-group .card:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.card-group .card:not(:first-child):not(:last-child) .card-img-top,
.card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
  border-radius: 0;
}

.card-columns {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.card-columns .card {
  display: inline-block;
  width: 100%;
}

.notes .heading {
  margin-top: 0px;
}
.notes p {
  margin: 0px;
}
.notes .panel{
  margin-bottom: 0px;
}
.notes .btn-delete.btn-default{
  border: none;
}

.plan-renewal td .row {margin:0;}
.plan-renewal td {vertical-align: middle !important;}

// default theme

$base-color: #607c8b;
$btn-background: #fff;
$btn-border: #ccc;
$btn-text-color: #333;
$btn-arrow: #333;


$token-background: $base-color;
$token-text-color: #fff;
$token-remove-color: #fff;

$box-shadow-color: #959595;
$list-hover-background: #f5f5f5;
$label-color: #000;
$selected-background: #e9f4ff;

.c-btn{
    background: $btn-background;
    border: 1px solid $btn-border;
    color: $btn-text-color;
}
.selected-list{
    .c-list{
        .c-token{
            background: $token-background;
            .c-label{
               color: $token-text-color;
            }
            .c-remove {
                svg {
                    fill: $token-remove-color;
                }
            }

        }
    }
    .c-angle-down, .c-angle-up{
        svg {
            fill: $btn-arrow;
        }
    }
}
.dropdown-list{
    ul{
        li:hover{
            background: $list-hover-background;
        }
    }
}
.arrow-up, .arrow-down {
  border-bottom: 15px solid #fff;
}

.arrow-2{
    border-bottom: 15px solid #ccc;
}
.list-area{
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 1px 5px $box-shadow-color;
}
.select-all{
    border-bottom: 1px solid #ccc;
}
.list-filter{
    border-bottom: 1px solid #ccc;
    .c-search{
        svg {
            fill: #888;
        }
    }
    .c-clear {
        svg {
            fill: #888;
        }
    }
}

.pure-checkbox {
    input[type="checkbox"]:focus + label:before, input[type="checkbox"]:hover + label:before{
        border-color: $base-color;
        background-color: #f2f2f2;
    }
    input[type="checkbox"] + label{
        color: $label-color;
    }
    input[type="checkbox"] + label:before{
        color: $base-color;
        border: 2px solid $base-color;
    }
    input[type="checkbox"] + label:after{
        background-color: $base-color;
    }
    input[type="checkbox"]:disabled + label:before{
        border-color: #cccccc;
    }
    input[type="checkbox"]:disabled:checked + label:before{
        background-color: #cccccc;
    }
    input[type="checkbox"] + label:after{
        border-color: #ffffff;
    }
    input[type="radio"]:checked + label:before{
        background-color: white;
    }
    input[type="checkbox"]:checked + label:before{
        background: $base-color;
    }
}
.selected-item{
    background: $selected-background;
}
.btn-iceblue {
    background: $base-color;
    border: 1px solid $btn-border;
    color: #fff;
}
